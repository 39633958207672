import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import IconSvg from '../../../../utils/svg';
import { Loader } from '../../../../screens/commons';

import {setSelectedTab} from '../../../../screens/coach/coach.actions';
import SessionRequest from '../SessionRequest/SessionRequest';
import SendYourMessage from '../SendYourMessage/SendYourMessage';
import 'react-phone-input-2/lib/style.css';

const initialData = {
    name: '',
    city: '',
    email: '',
    message: '',
    phone: '+55',
    subject: '',
};

export const navMenu = [
    {key: 'experimental_session', translationKey: 'REQUEST_YOUR_EXPERIMENTAL_SESSION'},
    {key: 'contact', translationKey: 'SEND_YOUR_MESSAGE'},
];

const SendMessage = ({ onClose, coach }) => {
    const [data, setData] = useState(initialData);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState(false);
    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();
    const dispatch = useDispatch(); 

    const closeConfirm = useCallback(() => {
        setOpen(false);
        setData(initialData);
        dispatch(setSelectedTab('experimental_session'));
    }, []);

    const close = useCallback(() => {
        setOpen(false);
        setData(initialData);
        dispatch(setSelectedTab('experimental_session'));
        onClose();
    }, []);

    
    const selectedTab = useSelector(({Coach}) => Coach.selectedTab);
    const {enableSiteExperimentalSession} = coach;

    const handleTabSelection = (key) => {
        dispatch(setSelectedTab(key));
    };

    useEffect(() => {
        dispatch(setSelectedTab(enableSiteExperimentalSession ? 'experimental_session' : 'contact'));
    }, [enableSiteExperimentalSession, setSelectedTab]);

    return (
        <React.Fragment>
            <IconSvg
                onClick={close}
                name="close-coach-modal"
                width={28}
                classDiv="container-close"
            />
            <div className="container-contact">
                {open && (
                    <SuccessModalWrapper
                        onClose={closeConfirm}
                        selectedTab={selectedTab}
                        error={error}
                        loading={loading}
                        t={t}
                    />
                )}
                {!enableSiteExperimentalSession && <h1>{t('SEND_YOUR_MESSAGE')}</h1>}

                <div className="container-form-contact">
                    <IconSvg
                        onClick={close}
                        name="dark-close"
                        width={25}
                        classDiv="container-close-dark"
                    />
                    {enableSiteExperimentalSession && (
                        <FormNav
                            t={t}
                            selectedTab={selectedTab}
                            handleTabSelection={handleTabSelection}
                        />
                    )}
                    <FormContent
                        data={data}
                        coach={coach}
                        setData={setData}
                        setOpen={setOpen}
                        setError={setError}
                        setLoading={setLoading}
                        selectedTab={selectedTab}
                        enableExperimentalSession={enableSiteExperimentalSession}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

function FormNav({t, selectedTab, handleTabSelection}) {
    return (
        <div className='div-btn'>
            {navMenu.map(({key, translationKey}) => {
                const isTabSelected = selectedTab === key;
                return (
                    <nav
                        className={`${isTabSelected ? 'tab_item_selected' : 'tab_item'}`}
                        onClick={() => handleTabSelection(key)}
                        key={key}
                    >
                        <span key={key} className='item'>{t(translationKey)}</span>
                    </nav>
                );
            })}
        </div>
    );
}

function FormContent({
    data,
    coach,
    setData,
    setOpen,
    setError,
    setLoading,
    selectedTab,
    enableExperimentalSession,
}) {
    if (!enableExperimentalSession) {
        return (
            <SendYourMessage
                coach={coach}
                data={data}
                setData={setData}
                setOpen={setOpen}
                setLoading={setLoading}
                setError={setError}
            />
        );
    }

    if (selectedTab === 'experimental_session') {
        return (
            <SessionRequest
                coach={coach}
                setOpen={setOpen}
                setLoading={setLoading}
                setError={setError}
            />
        );
    }

    return (
        <SendYourMessage
            coach={coach}
            data={data}
            setData={setData}
            setOpen={setOpen}
            setLoading={setLoading}
            setError={setError}
        />
    );
}

export default SendMessage;

const SuccessModalWrapper = ({ onClose, loading, error, t, selectedTab }) => {
    const stopPropagation = (e) => e.stopPropagation();
    const prevent = (e) => {
        if (!loading) {
            onClose(e);
        }
    };

    return (
        <div onClick={prevent} className="container-wrapper-success">
            <div className="wrapper-success" />
            <div className={`confirm ${selectedTab === 'experimental_session' ? 'request' : ''}`} onClick={stopPropagation}>
                {loading ? (
                    <Loader />
                ) : (
                    <React.Fragment>
                        <IconSvg
                            classDiv={`confirm-icon ${
                                error ? 'icon-error' : 'icon-success'
                            }`}
                            width={error ? 120 : 120}
                            height={error ? 40 : 120}
                            name={error ? 'dark-close' : 'confirm-icon'}
                        />
                        <p>
                            {error
                                ? t('MESSAGE_NOT_SENT_TRY_AGAIN')
                                : t(selectedTab === 'experimental_session' ? 'REQUEST_MESSAGE_SENT_SUCCESSFULLY' : 'MESSAGE_SENT_SUCCESSFULLY')}
                        </p>
                    </React.Fragment>
                )}
            </div>
        </div>
    );
};
